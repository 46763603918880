import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Radio,
    RadioGroup,
    Select,
    Text,
    Textarea,
} from '@chakra-ui/core';
import SEO from '../components/seo';
import Layout from '../components/layout';
import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image/index';

const encode = (data) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
};

const StyledBanner = styled(BackgroundImage)(
    ({ theme }) => `
    &::before,
    &::after {
        filter: brightness(0.6);
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 8px solid ${theme.color.brand};
    margin-bottom: 80px;
    height: 300px;
    text-align: center;
    width: 100%;
`
);

const StyledErrorMessage = styled(ErrorMessage)(
    ({ theme }) => `
        color: ${theme.color.red[500]};
        font-size: ${theme.fontSizes.sm};
    `
);

const StyledIntro = styled(Box)(
    ({ theme }) => `
        font-size: ${theme.fontSizes.xl};
        padding-bottom: 40px;
        margin: 0 auto 20px auto;
        width: 50%;
        
        @media (max-width: ${theme.breakpoints[0]}) {
            width: 75%;
        }
    `
);

const StyledField = styled(Field)(
    ({ theme }) => `
        border: 1px solid ${theme.color.gray[200]};
        width: 100%;
    `
);

const StyledLabel = styled(FormLabel)`
    margin-top: 10px;
`;

const StyledFormGroup = styled(Box)(
    ({ theme }) => `
        display: block;
        flex: 0 1 500px;
        
        input {
            border: 1px solid ${theme.color.gray[300]};
        }
        
        label div:first-of-type {
            border: 1px solid ${theme.color.gray[400]};
        }
        
        select {
            border: 1px solid ${theme.color.gray[300]};
        }
        
        textarea {
            border: 1px solid ${theme.color.gray[300]};
        }

        @media (max-width: ${theme.breakpoints[0]}) {
            flex: 0 1 200px;
            margin-top: 20px;
        }

    `
);

const StyledSubmitButton = styled(Button)(
    ({ theme }) => `
        background-color: ${theme.color.black};
        color: ${theme.color.white};
        margin-top: 40px;
        width: 200px;
    `
);

const validationSchema = yup.object().shape({
    carrier: yup
        .string()
        .min(2, 'Must be longer than 2 characters')
        .required('Carrier is required'),
    claimNumber: yup
        .string()
        .min(2, 'Must be longer than 2 characters')
        .required('Claim number is required'),
    insuredLastName: yup
        .string()
        .min(2, 'Must be longer than 2 characters')
        .required('Insured last name is required'),
    state: yup.string().required('Please select your state'),
    street: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    zip: yup.number().min(5, 'Must be at least 5 characters').required('Zip is required'),
    serviceTypes: yup.bool().oneOf([true]).required('At least one service type is required'),
});

const QuoteForm = () => {
    const data = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "claim-unsplash.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            <SEO title="Schedule a Claim" />
            <StyledBanner Tag="section" fluid={data.desktop.childImageSharp.fluid}>
                <Box>
                    <Text fontSize="5xl" color="white" fontWeight="semibold" pt="120px">
                        Schedule a Claim
                    </Text>
                </Box>
            </StyledBanner>
            <StyledIntro>
                <Text textAlign="center" mb="20px">
                    To Schedule a claim with Vertical Link Services, please fill out the following
                    information request form and submit directly to one of our claims specialist.
                </Text>
                <Text textAlign="center">
                    If you have any questions or need additional information please contact us at
                    <b> 1.800.909.2711</b> or email us at{' '}
                    <Link href="mailto:info@verticallinkservices.com">
                        info@verticallinkservices.com.
                    </Link>
                </Text>
            </StyledIntro>
            <Divider borderColor="gray.300" mb={['0', '40px']} />
            <Formik
                initialValues={{
                    carrier: '',
                    claimNumber: '',
                    insuredLastName: '',
                    state: '',
                    street: '',
                    city: '',
                    zip: '',
                    serviceTypes: '',
                    catastropheEvent: '',
                    claimReinspect: '',
                    adjusterContact: '',
                    adjusterName: '',
                    adjusterEmail: '',
                    adjusterPhone: '',
                }}
                onSubmit={(values, actions) => {
                    fetch('/', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        body: encode({ 'form-name': 'claim-form', ...values }),
                    })
                        .then(() => {
                            alert('Success');
                            actions.resetForm();
                        })
                        .catch(() => {
                            alert('Error');
                        })
                        .finally(() => actions.setSubmitting(false));
                }}
                validationSchema={validationSchema}>
                {() => (
                    <form name="claim-form" method="post" data-netlify="true">
                        <Flex
                            direction={['column', 'row']}
                            justifyContent="space-evenly"
                            m={['0 20px', '0']}>
                            <StyledFormGroup>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="carrier">Carrier</StyledLabel>
                                    <StyledField as={Input} name="carrier" required />
                                    <StyledErrorMessage name="carrier" component="ErrorMessage" />
                                </FormControl>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="insuredLastName">
                                        Insured Last Name
                                    </StyledLabel>
                                    <StyledField as={Input} name="insuredLastName" />
                                    <StyledErrorMessage
                                        name="insuredLastName"
                                        component="ErrorMessage"
                                    />
                                </FormControl>
                                <FormControl>
                                    <StyledLabel htmlFor="suite">Suite</StyledLabel>
                                    <StyledField as={Input} name="suite" />
                                </FormControl>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="state">State</StyledLabel>
                                    <StyledField
                                        as={Select}
                                        name="state"
                                        placeholder="Select State">
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </StyledField>
                                    <StyledErrorMessage name="state" component="ErrorMessage" />
                                </FormControl>
                            </StyledFormGroup>
                            <StyledFormGroup>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="claimNumber">Claim Number</StyledLabel>
                                    <StyledField as={Input} name="claimNumber" />
                                    <StyledErrorMessage
                                        name="claimNumber"
                                        component="ErrorMessage"
                                    />
                                </FormControl>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="street">Street Address</StyledLabel>
                                    <StyledField as={Input} name="street" />
                                    <StyledErrorMessage name="street" component="ErrorMessage" />
                                </FormControl>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="city">City</StyledLabel>
                                    <StyledField as={Input} name="city" />
                                    <StyledErrorMessage name="city" component="ErrorMessage" />
                                </FormControl>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="zip">Zip</StyledLabel>
                                    <StyledField as={Input} name="zip" />
                                    <StyledErrorMessage name="zip" component="ErrorMessage" />
                                </FormControl>
                            </StyledFormGroup>
                        </Flex>
                        <Divider borderColor="gray.300" mb={['0', '40px']} mt="40px" />
                        <Flex
                            direction={['column', 'row']}
                            justifyContent="space-evenly"
                            m={['0 20px', '0']}>
                            <StyledFormGroup>
                                <Text fontSize="2xl" fontWeight="semibold" mb="10px">
                                    Service Types
                                </Text>
                                <FormControl isRequired>
                                    <CheckboxGroup name="serviceTypes" variantColor="blue">
                                        <Field
                                            as={Checkbox}
                                            type="checkbox"
                                            name="serviceTypes"
                                            value="ladderAssist"
                                            mt="5px">
                                            Ladder Assist
                                        </Field>
                                        <Field
                                            as={Checkbox}
                                            type="checkbox"
                                            name="serviceTypes"
                                            value="directInspection"
                                            mt="5px">
                                            Direct Inspection
                                        </Field>
                                        <Field
                                            as={Checkbox}
                                            type="checkbox"
                                            name="serviceTypes"
                                            value="tarpRemove"
                                            mt="5px">
                                            Tarp Installation/Removal
                                        </Field>
                                        <Field
                                            as={Checkbox}
                                            type="checkbox"
                                            name="serviceTypes"
                                            value="emergencyTarp"
                                            mt="5px">
                                            Emergency Tarp Installation/Removal
                                        </Field>
                                        <Field
                                            as={Checkbox}
                                            type="checkbox"
                                            name="serviceTypes"
                                            value="ladderAssistReport"
                                            mt="5px">
                                            Ladder Assist with Report
                                        </Field>
                                        <Field
                                            as={Checkbox}
                                            type="checkbox"
                                            name="serviceTypes"
                                            value="itelMobile"
                                            mt="5px">
                                            Disaster Response Team
                                        </Field>
                                    </CheckboxGroup>
                                    <StyledErrorMessage
                                        name="serviceTypes"
                                        component="ErrorMessage"
                                    />
                                </FormControl>
                            </StyledFormGroup>
                            <StyledFormGroup>
                                <Text fontSize="2xl" fontWeight="semibold" mb="10px">
                                    Catastrophe Event
                                </Text>
                                <FormControl isRequired>
                                    <Text mb="5px">
                                        Is this claim due to a catastrophe weather event?
                                    </Text>
                                    <RadioGroup
                                        name="catastropheEvent"
                                        variantColor="blue"
                                        isInline
                                        defaultValue="catastropheNo">
                                        <Field
                                            as={Radio}
                                            type="radio"
                                            name="catastropheEvent"
                                            value="catastropheYes"
                                            mt="5px">
                                            Yes
                                        </Field>
                                        <Field
                                            as={Radio}
                                            type="radio"
                                            name="catastropheEvent"
                                            value="catastropheNo"
                                            mt="5px">
                                            No
                                        </Field>
                                        <Field
                                            as={Radio}
                                            type="radio"
                                            name="catastropheEvent"
                                            value="catastropheUnknown"
                                            mt="5px">
                                            Unknown
                                        </Field>
                                    </RadioGroup>
                                    <StyledErrorMessage
                                        name="catastropheEvent"
                                        component="ErrorMessage"
                                    />
                                </FormControl>
                                <Text fontSize="2xl" fontWeight="semibold" mb="10px" mt="20px">
                                    Re-Inspect
                                </Text>
                                <FormControl isRequired>
                                    <Text mb="5px">Is this claim a re-inspect?</Text>
                                    <RadioGroup
                                        name="claimReinspect"
                                        variantColor="blue"
                                        isInline
                                        defaultValue="claimReinspectNo">
                                        <Field
                                            as={Radio}
                                            type="radio"
                                            name="claimReinspect"
                                            value="claimReinspectYes"
                                            mt="5px">
                                            Yes
                                        </Field>
                                        <Field
                                            as={Radio}
                                            type="radio"
                                            name="claimReinspect"
                                            value="claimReinspectNo"
                                            mt="5px">
                                            No
                                        </Field>
                                    </RadioGroup>
                                    <StyledErrorMessage
                                        name="claimReinspect"
                                        component="ErrorMessage"
                                    />
                                </FormControl>
                            </StyledFormGroup>
                        </Flex>
                        <Divider borderColor="gray.300" mb={['0', '40px']} mt="40px" />
                        <Flex
                            direction={['column', 'row']}
                            justifyContent="space-evenly"
                            m={['0 20px', '0']}>
                            <StyledFormGroup>
                                <Text fontSize="2xl" fontWeight="semibold" mb="10px">
                                    Adjuster Contact Information
                                </Text>
                                <FormControl isRequired>
                                    <Text mb="5px">I am an independent adjuster</Text>
                                    <RadioGroup
                                        name="adjusterContact"
                                        variantColor="blue"
                                        isInline
                                        defaultValue="independentAdjusterNo">
                                        <Field
                                            as={Radio}
                                            type="radio"
                                            name="adjusterContact"
                                            value="independentAdjusterYes"
                                            mt="5px">
                                            Yes
                                        </Field>
                                        <Field
                                            as={Radio}
                                            type="radio"
                                            name="adjusterContact"
                                            value="independentAdjusterNo"
                                            mt="5px">
                                            No
                                        </Field>
                                    </RadioGroup>
                                    <StyledErrorMessage
                                        name="adjusterContact"
                                        component="ErrorMessage"
                                    />
                                </FormControl>
                                <FormControl>
                                    <StyledLabel htmlFor="adjusterName">Name</StyledLabel>
                                    <StyledField as={Input} name="adjusterName" />
                                    <StyledErrorMessage
                                        name="adjusterName"
                                        component="ErrorMessage"
                                    />
                                </FormControl>
                                <FormControl>
                                    <StyledLabel htmlFor="adjusterEmail">Email</StyledLabel>
                                    <StyledField as={Input} name="adjusterEmail" />
                                    <StyledErrorMessage
                                        name="adjusterEmail"
                                        component="ErrorMessage"
                                    />
                                </FormControl>
                                <FormControl>
                                    <StyledLabel htmlFor="adjusterPhone">Phone</StyledLabel>
                                    <StyledField as={Input} name="adjusterPhone" />
                                    <StyledErrorMessage
                                        name="adjusterPhone"
                                        component="ErrorMessage"
                                    />
                                </FormControl>
                            </StyledFormGroup>
                            <StyledFormGroup>
                                <Text fontSize="2xl" fontWeight="semibold" mb="10px">
                                    Notes
                                </Text>
                                <FormControl>
                                    <StyledLabel htmlFor="notes">Notes</StyledLabel>
                                    <StyledField as={Textarea} name="notes" />
                                </FormControl>
                            </StyledFormGroup>
                        </Flex>
                        <Box w="100%" textAlign="center">
                            <StyledSubmitButton
                                variant="solid"
                                borderRadius="0"
                                type="submit"
                                size="lg"
                                _hover={{ bg: '#ffca10', color: 'black' }}>
                                Send
                            </StyledSubmitButton>
                        </Box>
                        <input type="hidden" name="form-name" value="claim-form" />
                    </form>
                )}
            </Formik>
        </Layout>
    );
};

export default QuoteForm;
